import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  numberedEnv,
  localizeKey,
  sentryDSNKey,
  isNumberedEnv,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "dev",
  appRegion: "eu",
  appEnv: "eu-dev",
  urls: {
    apiBaseUrl: "https://core.api.dev.holobuilder.eu",
    apiBaseComUrl: "https://core.api.dev.holobuilder.com",
    apiBaseEuUrl: "https://core.api.dev.holobuilder.eu",
    sphereDashboardUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.eu"
        : "https://sphere.dev.holobuilder.eu",
    dashboard20ComUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.com"
        : "https://sphere.dev.holobuilder.com",
    dashboard20EuUrl:
      numberedEnv === "dev1"
        ? "https://dev1.sphere.dev.holobuilder.eu"
        : "https://sphere.dev.holobuilder.eu",
    sphereEntryPageComUrl: "https://entry.dev.holobuilder.com",
    sphereEntryPageEuUrl: "https://entry.dev.holobuilder.eu",
    sphere10Url: "https://www.dev.farosphere.com",
    sphereEntryPageApi: "https://entry.dev.holobuilder.eu/api",
  },
  features: {
    localizeKey,
    sphere10Auth0Domain: "login.eu.dev.farosphere.com",
    sphere10Auth0ClientId: "KBygi7sREPoE6KHQY58bPJKsahVcVZB6",
    streamAppAuth0ClientId: "q84k4DiTZc8qVYLfHYTAiE9e3gy7dqx0",
    ulmClientsFlags: {
      ulmClientDefaultFlags: "default,directSignup,noSphere1Login,sphere2Login",
      ulmClientSphereDashboardFlags:
        "sphereDashboard,directSignup,noSphere1Login,sphere2Login",
      ulmClientConnectFlags: "connect,directSignup,noSphere1Login,sphere2Login",
      ulmClientSceneFlags: "scene,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamOrbisFlags:
        "streamOrbis,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamFocusFlags:
        "streamFocus,directSignup,sphere1Login,sphere2Login",
      ulmClientSphereViewerFlags:
        "sphereViewer,directSignup,noSphere1Login,sphere2Login",
    },
    hasCompanySSO: true,
    sentryDSNKey,
  },
  oauth: {
    providers: ["autodesk", "procore", "faro-sphere"],
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+dev@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};
