export function getNumberedEnv(host: string): string | null {
  const numberedEnv = host.match(/^dev\d{1,2}/);
  return numberedEnv?.length ? numberedEnv[0] : null;
}

export function isHostNumberedEnv(host: string): boolean {
  return /^dev\d{1,2}/.test(host);
}

export const appVersion: string = "2.0.0-bugfix-ST-2643-allow-to-add-multiple-names-in-register.0";
export const appTimestamp: string = "2025-01-20T14:09:07.000Z";
export const commitId: string = "1c90a540e5d4c9bcd679beef0b77d0695107cc32";
export const commitUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/EntryPage/commit/1c90a540e5d4c9bcd679beef0b77d0695107cc32?refName=refs%2Fheads%2Fmaster";
export const pullRequestId: string = "30614";
export const pullRequestUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/EntryPage/pullrequest/30614";
export const jiraTicketId: string = "ST-2643";
export const jiraTicketUrl: string = "https://faro01.atlassian.net/browse/ST-2643";

export const localizeKey = "iKNI0OJMw3RLe";
export const sentryDSNKey =
  "https://20e8bee1014c8ffa9f8b67f3d69a9709@o381590.ingest.sentry.io/4505912288411648";

export const isNumberedEnv = isHostNumberedEnv(window.location.host);
export const numberedEnv = isNumberedEnv
  ? getNumberedEnv(window.location.host)
  : null;
