import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  localizeKey,
  sentryDSNKey,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "production",
  appRegion: "eu",
  appEnv: "com-production",
  urls: {
    apiBaseUrl: "https://core.api.holobuilder.com",
    apiBaseComUrl: "https://core.api.holobuilder.com",
    apiBaseEuUrl: "https://core.api.holobuilder.eu",
    sphereDashboardUrl: "https://sphere.holobuilder.com",
    dashboard20ComUrl: "https://sphere.holobuilder.com",
    dashboard20EuUrl: "https://sphere.holobuilder.eu",
    sphereEntryPageComUrl: "https://entry.holobuilder.com",
    sphereEntryPageEuUrl: "https://entry.holobuilder.eu",
    sphere10Url: "https://www.farosphere.com",
    sphereEntryPageApi: "https://entry.holobuilder.com/api",
  },
  features: {
    localizeKey,
    sphere10Auth0Domain: "login.eu.farosphere.com",
    sphere10Auth0ClientId: "u3aqaZnjxpvnAdfeBqvIgAmZEGNUDH54",
    streamAppAuth0ClientId: "tDrgNkzwxHR9awvwu5TI6aV39sLyq4Fv",
    ulmClientsFlags: {
      ulmClientDefaultFlags: "default,sfSignup,noSphere1Login,sphere2Login",
      ulmClientSphereDashboardFlags:
        "sphereDashboard,sfSignup,noSphere1Login,sphere2Login",
      ulmClientConnectFlags: "connect,directSignup,noSphere1Login,sphere2Login",
      ulmClientSceneFlags: "scene,directSignup,noSphere1Login,sphere2Login",
      ulmClientStreamOrbisFlags:
        "streamOrbis,sfSignup,noSphere1Login,sphere2Login",
      ulmClientStreamFocusFlags:
        "streamFocus,directSignup,sphere1Login,sphere2Login",
      ulmClientSphereViewerFlags:
        "sphereViewer,sfSignup,noSphere1Login,sphere2Login",
    },
    hasCompanySSO: true,
    sentryDSNKey,
  },
  oauth: {
    providers: ["autodesk", "procore", "bluebeam", "google", "faro-sphere"],
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support@holobuilder.com",
  isNumberedEnv: false,
  numberedEnv: null,
};
